import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { RichText } from 'prismic-reactjs';
import LinkUtil from '../../utils/link';

const Root = styled.div`
  width: 100%;
`;

const Logo = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.image});
`;

function PartnerItem({ data }) {
  return (
    <Root>
      <a
        href={LinkUtil.url(data.link)}
        target="_blank"
        title={RichText.asText(data.title)}
        rel="noopener noreferrer"
      >
        {data.partner_logo && (
          <Logo
            image={data.partner_logo?.url}
            title={RichText.asText(data.title)}
          />
        )}
      </a>
    </Root>
  );
}

PartnerItem.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default PartnerItem;
