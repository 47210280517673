import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '@digitaliseringsbyran/gatsby-source-prismic-graphql';

import styled from 'styled-components';

import Hero from '../../components/Hero';
import Button from '../../components/Button';

// import LinkUtil from '../../utils/link';

const Root = styled.div`
  position: relative;
  width: 100%;
`;

function HeroImage({ data, pageTitle }) {
  return (
    <Root>
      <Hero
        preHeading={
          data.primary.pre_title && RichText.asText(data.primary.pre_title)
        }
        heading={RichText.asText(data.primary.title)}
        image={data.primary.imageSharp}
        actions={
          data.primary.link && (
            <Button link={data.primary.link} variant="fill">
              {data.primary.link_label}
            </Button>
          )
        }
        big
        pageTitle={pageTitle}
      >
        {data.primary.text && (
          <RichText render={data.primary.text} linkResolver={linkResolver} />
        )}
      </Hero>
    </Root>
  );
}

HeroImage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageTitle: PropTypes.bool,
};

HeroImage.defaultProps = {
  pageTitle: false,
};

export default HeroImage;
