/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { linkResolver } from '@digitaliseringsbyran/gatsby-source-prismic-graphql';

import { RichText } from 'prismic-reactjs';
import LinkFragment from '../utils/linkFragment';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import HeroCarousel from '../components/HeroCarousel';
import PartnerItem from '../components/PartnerItem';

import { breakpoint } from '../theme';

const IntroSection = styled.div`
  padding-top: ${rem(50)};
  padding-bottom: ${rem(50)};

  ${breakpoint('large')} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(60)};
  }
`;

const IntroContent = styled(Col)`
  text-align: center;
  width: 100%;
  max-width: ${rem(500)};
  margin: 0 auto;

  ${breakpoint('mediumlarge')} {
    max-width: ${rem(570)};
  }
`;

const LogoContainer = styled.div`
  border-top: ${(props) => {
    const { border } = props.theme.global;
    return `${border.width} ${border.style} ${border.color}`;
  }};
  padding-top: 2rem;
  padding-bottom: 2rem;
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: auto;
  text-align: center;
`;

const GridItem = styled(Col)`
  display: inline-block;
  width: ${rem(185)};
  /* padding: ${(props) => props.theme.spacing.gutter}; */

  ${breakpoint('large')} {
    width: ${rem(205)};
  }
`;

const LogoSectionTitle = styled.h4`
  display: block;
  width: 100%;
  font-weight: ${(props) => props.theme.typography.weights.heading.normal};
  color: ${(props) => props.theme.palette.colors.greyLight};
  font-size: ${rem(17)};
`;

function App({ data }) {
  const { allHomepages, allPartners: partners } = data.prismic;
  const page = allHomepages.edges[0].node;

  // console.log(data);

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title)}
        description={page.seo_description}
      />

      {page.hero_items && page.hero_items.length && (
        <HeroCarousel items={page.hero_items} />
      )}

      {page.content && page.content.length && RichText.asText(page.content) && (
        <IntroSection>
          <Row>
            <IntroContent>
              <RichText render={page.content} linkResolver={linkResolver} />
            </IntroContent>
          </Row>
        </IntroSection>
      )}

      <LogoContainer>
        <LogoSectionTitle>Our friends</LogoSectionTitle>
        {partners.edges.map(({ node: partner }) => {
          return (
            <GridItem key={partner._meta.id}>
              <PartnerItem data={partner} />
            </GridItem>
          );
        })}
      </LogoContainer>
    </Layout>
  );
}

App.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

App.fragments = [LinkFragment];

export const query = graphql`
  query {
    prismic {
      allHomepages(first: 1) {
        edges {
          node {
            title
            hero_title
            hero_text
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            seo_meta_title
            seo_description
            hero_link {
              ...LinkFragment
            }
            hero_items {
              ... on PRISMIC_HomepageHero_itemsImage_item {
                type
                label
                primary {
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 75) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  link {
                    ...LinkFragment
                  }
                  title
                  text
                  pre_title
                  link_label
                }
              }
              ... on PRISMIC_HomepageHero_itemsVideo_item {
                type
                label
                primary {
                  link {
                    ...LinkFragment
                  }
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 75) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  link_label
                  pre_title
                  text
                  title
                  video {
                    ... on PRISMIC__FileLink {
                      _linkType
                      name
                      size
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      allSettingss {
        edges {
          node {
            page_for_partners {
              ...LinkFragment
            }
            page_for_clients {
              ...LinkFragment
            }
          }
        }
      }
      allPartners(sortBy: title_ASC, first: 8) {
        edges {
          node {
            _linkType
            _meta {
              id
              lang
              lastPublicationDate
              tags
              type
              uid
            }
            content
            hero_text
            link {
              ...LinkFragment
            }
            _linkType
            partner_logo
            seo_description
            seo_meta_title
            title
          }
        }
        totalCount
      }
    }
  }
`;

export default App;
